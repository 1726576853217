export const ACTIVATION_VIEW_DATA = {
  success: {
    title   : '930e18f77' ,
    desc    : '91ba3845b',
    btnText : '2509c6f6d'
  },
  link_expired: {
    title   : '2f15f96c8',
    desc    : '0b8fd7630',
    btnText : 'd47542cd3'
  },
  link_was_resend: {
    title   : '5114e9c83',
    desc    : 'c1e78424b',
    btnText : '01cf90c98'
  }
}
