import React, { useState } from 'react'
import { navigate } from 'gatsby'
import qs from 'query-string'
import { useIntl } from 'react-intl'
import { useToasts } from 'react-toast-notifications'
import { Button } from 'components'
import { Wrapper, Title, Desc } from './styles'
import { ACTIVATION_VIEW_DATA } from './constant'
import API, { AuthURL } from 'commons/API'

const ActivationView = ({ 
  type, 
  tokenUser, 
  queryParams 
}) => {

  const { addToast } = useToasts()
  const intl = useIntl()
  const [isLoading, setLoading] = useState(false)

  const handleClickLinkExpired = async () => {
    setLoading(true)

    API({
      url    : AuthURL.ResendTokenLead,
      method : 'post',
      data   : {
        activation_link : location.origin + location.pathname,
        next_link       : queryParams.next_link
      },
      headers: {
        authorization: `Bearer ${tokenUser}`
      }
    })
      .then(() => {
        const params = qs.stringify({ modePage: 'link_was_resend', t: tokenUser })
        navigate(`${location.pathname}?${params}`)
      })
      .catch((error) => addToast(error.response.data.message, { appearance: 'warning' }))
      .finally(() => setLoading(false))
  }

  const handleClickSuccess = () => {
    const nextLink = queryParams.next_link === 'undefined' ? '' : queryParams.next_link
    const params = qs.stringify({ redirect_to: nextLink, email: queryParams.email })
    window.open(`${process.env.GATSBY_DASHBOARD_URL}/login?${params}`)
  }

  const handleClickLinkResend = () => {
    window.open(process.env.GATSBY_DASHBOARD_URL)
  }

  const handleClickBtn = ({ type }) => {
    if(type === 'link_expired') handleClickLinkExpired()
    else if(type === 'success') handleClickSuccess()
    else handleClickLinkResend()
  
    return ''
  }

  return(
    <Wrapper>
      <Title>
        { intl.formatMessage({ id: ACTIVATION_VIEW_DATA[type].title }) }
      </Title>
      <Desc>
        { intl.formatMessage({ id: ACTIVATION_VIEW_DATA[type].desc }) }
      </Desc>
      <Button 
        isLoading={ isLoading }
        buttonType="warning"
        onClick={ () => handleClickBtn({ type }) }
      >
        { intl.formatMessage({ id: ACTIVATION_VIEW_DATA[type].btnText }) }
      </Button>
    </Wrapper>
  )
}

export default ActivationView
